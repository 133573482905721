.legendStyle {
  display: inline-block;
  position: absolute;
  color: #ffffff;
  text-shadow: 1px 1px black;
  z-index: 100 !important;
  font-weight: bold;
}


@media not all and (pointer:coarse) {
  .legendStyle {
    vertical-align: middle;
    bottom: 60px;
    right: 10px;
  }

  .legendItem {
    height: 20px;
    line-height: 20px;
    width: 35px;
    text-align: center;
  }
}


@media all and (pointer:none), (pointer:coarse) {
  .legendStyle {
    width:100%;
    bottom: 0;
    left: 0;
    overflow:scroll;
    white-space: nowrap;
  }

  .legendItem {
    display: inline-block;
    padding: 4px;
    font-size: 14px;
  }
}