.controlPanel {
  position: absolute;
  bottom: 5px;
}


.controlButtons {
  display: inline-block;
  white-space: nowrap;
  z-index: 101 !important;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.controlButtonIcon {
  color: white;
  font-size: 35px;
}

.datePicker {
    position: absolute;
    bottom: 5px;
    left: 180px;
    width: 80px;
    z-index: 101 !important;
    background-color: Transparent;
    background-repeat:no-repeat;
    cursor: pointer;
  }

.timestampSelector {
    position: absolute;
    bottom: 5px;
    left: 250px;
    z-index: 101 !important;
  }
  
  
.MuiInputBase-input.MuiInput-input {
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px black;
}

@media not all and (pointer:coarse) {
  div[class^="MuiInput"] {
    font-size: x-large;
  }

  .datePicker {
    left: 230px;
    width: 130px;
  }

  .controlPanel {
    width: 95%;
    text-align: center;
  }

  .controlButtons {
    padding-left: 20px;
    padding-right: 20px;
  }  
}

@media (pointer:none), (pointer:coarse) {
  .controlPanel {
    /* position: absolute; */
    /* left: 230px; */
    width: 95%;
    text-align: center;
    bottom: 65px;
  }

  .controlButtons {
    padding-left: 15px;
    padding-right: 15px;
  }  

  .controlButtonIcon {
    font-size: 25px;
  }

  .datePicker {
    bottom: 25px;
  }

  .timestampSelector {
    bottom: 27px;
    left: 180px;
  }

}